<template>
  <span>
    <span v-if="update">
      <span v-if="coords">
        <v-btn color="primary" text @click="updateLocation" ><v-icon dense>mdi-refresh</v-icon></v-btn>
        {{$t('accuracy')}}: {{ coords.accuracy }} m
        <v-btn color="primary" text @click="saveUpdatedLocation" v-if="coords">{{$t('use')}}</v-btn>
      </span>
      <span v-else>
        {{$t('status')}}
      </span>
    </span>
    <span v-else>
      <LatLong :location="location" v-if="location" />
      <span v-else>{{ not_set_label || $t('notSet')}}</span>
      <v-btn color="primary" text @click="location = null; update=true" v-if="updateable"><v-icon dense>mdi-refresh</v-icon></v-btn>
    </span>
  </span>
</template>

<script>
import LatLong from '@/components/LatLong';

export default {

  components: { LatLong },

  props: ['value', 'no_update', 'not_set_label'],

  data: () => ({
    update: false,
    location: null,
  }),

  computed: {
    updateable() { return this.no_update==null; },
    status() { return this.$store.getters.geolocationStatus; },
    geolocation() { return this.$store.getters.geolocation; },
    coords() {
      let r = null;
      if ((this.geolocation) && (this.geolocation.coords)) { r = this.geolocation.coords; }
      return r;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.location = to; }
    },
  },

  methods: {
    updateLocation() {
      this.update = true;
      this.$store.dispatch('updateGeolocation');
    },
    saveUpdatedLocation() {
      if (this.coords) {
        this.update = false;
        this.location = { latitude: this.coords.latitude, longitude: this.coords.longitude };
        this.$emit('input', this.location);
      }
    },
  },

};
</script>


<i18n>
{
  "en": {
    "accuracy": "Accuracy",
    "use": "Use",
    "status": "GPS Location Status",
    "notSet": "Not Set"
  },
  "es": {
    "accuracy": "Precisión",
    "use": "Usar",
    "status": "Estado de ubicación GPS",
    "notSet": "No establecido"
  },
  "sw": {
    "accuracy": "Usahihi",
    "use": "Tumia",
    "status": "Hali ya Mahali pa GPS",
    "notSet": "Haijawekwa"
  }
}
</i18n>
