<template>
  <v-icon title="Sign Out" color="primary" dense left @click="signOut">mdi-logout</v-icon>
</template>

<script>
  export default {
    methods: {
      signOut() { this.$store.dispatch('logout'); },
    }
  };
</script>
