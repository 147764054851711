<template>
  <Urban         v-if="formChoice==='urban'" />
  <DefaultCreate v-else />
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import fields from '@/mixins/fields';

  import DefaultCreate from '@/views/purchases/partners/Default';
  import Urban from '@/views/purchases/partners/Urban';

  export default {

    components: { DefaultCreate, Urban },

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      formChoice() {
        let f = 'default';
        if (['urban_zm', 'urban_ke'].includes(this.partnerCode)) { f = 'urban'; }
        return f;
      },
    },

  }
</script>
