<template>
  <div>
    <slot>
      <div class="text-left">
        <span class="text-h4" @click="$emit('title')">
          <v-icon class="hidden-sm-and-down" v-if="icon" dense>{{icon}}</v-icon>
          {{title}}
        </span>
        <span class="text-subtitle-1 hidden-sm-and-down" v-if="showSubtitle" @click="$emit('subtitle')">
          {{subtitle}}
        </span>
      </div>
    </slot>
  </div>
</template>

<script>

export default {
  props: ['icon', 'title', 'subtitle'],
  computed: {
    showSubtitle() { return (this.subtitle && ((this.title || '').length < 24)); }
  }
};
</script>
