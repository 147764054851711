<template>
  <v-text-field
    v-model="email"
    :rules="emailRules"
    :label="$t('emailAddress')"
     @input="handleInput"
  ></v-text-field>
</template>

<script>
  export default {
    props: ['value'],
    data: () => ({
      email: null,
      emailRules: [
        v => (v || '').length==0 || /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'Invalid email'
      ],
    }),
    watch: {
      value: {
        immediate: true,
        handler(to) { this.email = to; }
      },
    },
    methods: {
      handleInput() {
        this.$emit('input', this.email);
      },
    },
  };
</script>


<i18n>
{
  "en": {
    "emailAddress": "Email Address"
  },
  "es": {
    "emailAddress": "Dirección de correo electrónico"
  },
  "sw": {
    "emailAddress": "Barua Pepe"
  }
}
</i18n>
