<template>
  <span>
    {{ phoneNumberFromMsisdn | regexGroups(/(\d\d\d)(\d\d\d)(\d+)/) }}
  </span>
</template>

<script>
import msisdnToTelephone from '@/mixins/msisdnToTelephone';
export default {
  props: ['msisdn'],
  mixins: [msisdnToTelephone],
};
</script>
