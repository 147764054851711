<template>
  <PageHeader :title="customer.name"  back save >
    <v-container>

      <v-row>
        <v-col>
          <PageOne v-if="showPage==='one'" />
          <PageTwo v-else />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
            </div>
            <div class="text-right">
              <v-btn @click="pageDecr" color="primary" class="mr-2">{{$t('Previous')}}</v-btn>
              <v-btn @click="pageIncr" color="primary">{{$t('Next')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader';
  import PageOne from '@/views/partners/pptl_tz/PageOne';
  import PageTwo from '@/views/partners/pptl_tz/PageTwo';

  export default {

    components: {
      PageHeader,
      PageOne,
      PageTwo,
    },

    data: () => ({
      customer: {},
      validForm: false,
      pageNumber: 1,
    }),

    computed: {
      showPage() {
        let r = 'one';
        if (this.pageNumber===2) { r = 'two' };
        return r;
      },
    },

    methods: {
      pageDecr() { this.pageNumber = this.pageNumber - 1; },
      pageIncr() { this.pageNumber = this.pageNumber + 1; },
    }


  }
</script>


<i18n>
{
  "en": {
    "Primary Crop": "Primary Crop",
    "Secondary Crop": "Secondary Crop",
    "First Name": "First Name",
    "Surname": "Surname",
    "Notes": "Notes",
    "Previous": "Previous",
    "Next": "Next",
    "Clear": "Clear"
  },
  "es": {
    "Primary Crop": "Cultivo Principal",
    "Secondary Crop": "Secondary Crop",
    "First Name": "Primer nombre",
    "Surname": "Apellido",
    "Notes": "Notas",
    "Previous": "Salvar o Editar",
    "Next": "Próximo",
    "Clear": "Reiniciar"
  },
  "sw": {
    "Primary Crop": "Mazao ya Msingi",
    "Secondary Crop": "Mazao ya Sekondari",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la jina",
    "Notes": "Noti",
    "Previous": "Iliyotangulia",
    "Next": "Ijayo",
    "Clear": "Wazi"
  }
}
</i18n>
