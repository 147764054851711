<template>

  <v-container fluid class="pa-0">

    <v-snackbar v-model="onlineWarning" timeout="5000" >
      Search online for farmers and contacts.
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="onlineWarning = false" >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="favouritingInProgress" timeout="5000" >
      Customers will be added to your list of favourites, and cached for offline access.
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="favouritingInProgress = false" >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <template v-if="showCustomerForPartnerIdentifier">
      <v-row no-gutters class="my-2">
        <v-col cols="12">
          <v-btn block color="primary" @click="showCustomerForPartnerIdentifier = null">
            Back
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <LoadAndShow :partnerIdentifier="showCustomerForPartnerIdentifier" />
        </v-col>
      </v-row>
    </template>

    <template v-else>

      <v-row no-gutters class="my-2">

        <v-col cols="12" v-if="selected.length==0">
          <v-text-field  v-model="searchText" hide-details label="Filled" :placeholder="$t('search')" full-width filled rounded light dense single-line append-icon="mdi-magnify" />
        </v-col>

        <v-col cols="9" v-if="selected.length!=0">
          <v-text-field  v-model="searchText" hide-details label="Filled" :placeholder="$t('search')" full-width filled rounded light dense single-line append-icon="mdi-magnify" />
        </v-col>
        <v-col cols="3" v-if="selected.length!=0">
          <v-btn color="primary" @click="save">
            <v-icon>mdi-star-outline</v-icon>
            Fav
          </v-btn>
        </v-col>


      </v-row>


      <v-row no-gutters v-if="searchText.length<3">
        <v-col cols="12">
          {{$t('instructions')}}
        </v-col>
      </v-row>

      <template v-if="searchText.length>=3">
        <v-row no-gutters v-if="customers.length===0">
          <v-col cols="12">
            {{$t('noResults')}}
          </v-col>
        </v-row>

        <v-row no-gutters v-else >
          <v-col cols="12">
            <Customers :customers="customers" @show="show" @selectionChange="selectionChange" />
          </v-col>
        </v-row>
      </template>

    </template>
    

  </v-container>
  
</template>


<script>

  import Customers from '@/views/search/components/Index';
  import LoadAndShow from '@/views/customers/components/LoadAndShow';

  export default {

    components: {
      Customers,
      LoadAndShow,
    },

    data: () => ({
      searchText: '',
      onlineWarning: true,
      favouritingInProgress: false,
      showCustomerForPartnerIdentifier: null,
      selected: [],
    }),

    computed: {
      customers() { return this.$store.getters.searchResults; },
    },

    watch: {
      searchText: function(s) { this.$store.dispatch('updateOnlineSearch', s); }
    },

    methods: {
      show(customer) {
        this.$router.push({ name: 'ShowCustomer', query: { uuid: customer.uuid } });
      },
      selectionChange(selected) { this.selected = selected; },
      save() {
        this.favouritingInProgress = true;
        this.$store.dispatch('addCustomersToCache', this.selected.map( e => e.uuid));
      },
    },

  }
</script>


<i18n>
{
  "en": {
    "instructions": "We will start searching once you have entered three characters. You can search for names, telephone numbers, identity numbers and areas.",
    "search": "Search",
    "noResults": "No profiles match your search criteria."
  },
  "es": {
    "instructions": "La búsqueda iniciará una vez ingrese los primeros tres caracteres. Puede buscar nombres, números de teléfono, números de identidad y áreas.",
    "search": "Búsqueda",
    "noResults": "Ningún perfil coincide con sus criterios de búsqueda."
  },
  "sw": {
    "instructions": "Tutaanza kutafuta ukishaingiza herufi tatu. Unaweza kutafuta majina, nambari za simu, nambari za kitambulisho na maeneo.",
    "search": "Tafuta",
    "noResults": "Mafuta ya Ningún sanjari na con criterios de búsqueda."
  }
}
</i18n>
