<template>
  <v-container fluid class="pa-0">

    <v-row no-gutters class="mb-2" v-if="customer">
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <Heading icon="mdi-focus-field">
            <router-link :to="{name: 'ShowCustomer', query: { partnerIdentifier: partnerIdentifier } }">{{customer.name}}</router-link>
          </Heading>
          <div>
            <TakePhoto @click="saveImage" v-if="fieldId">
              <v-btn color="primary" small dense class="ml-1"><v-icon dense>mdi-camera</v-icon></v-btn>
            </TakePhoto>
          </div>
        </div>
      </v-col>
    </v-row>


    <v-row no-gutters>

      <v-col cols="12" >
        <v-select :items="fieldList" label="Field" solo v-model="fieldId"></v-select>
      </v-col>

      <v-col cols="12" v-if="showAnnotations">
        <v-select :items="annotationsList" label="Standard Recommendations" solo :disabled="changed" @change="setDefault" v-model="defaultRecommendation"></v-select>
      </v-col>

      <v-col cols="12">
        <v-text-field label="Subject" v-model="subject" @change="changed=true;"></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-textarea outlined label="Recommendations / Actions" v-model="text" @change="changed=true;"></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-select v-model="actions" :items="actionsList" label="Select Items" multiple>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >
              (+{{ actions.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </v-col>

      <v-simple-table dense>
        <tbody>
          <tr>
            <td>Follow Up</td>
            <td><TimeRangePicker v-model="followUpAt" hideDuration /></td>
          </tr>
          <tr>
            <td>Schedule Appointment</td>
            <td><TimeRangePicker v-model="appointment" /></td>
          </tr>
        </tbody>
      </v-simple-table>
      
    </v-row>

    <v-row no-gutters>
      <v-col cols="4" class="pr-1">
        <v-btn color="secondary" style="width: 100%;" :to="{name: 'ShowCustomer', query: { partnerIdentifier: partnerIdentifier } }" >Back</v-btn>
      </v-col>
      <v-col cols="4" class="pr-1">
        <v-btn color="warning" style="width: 100%;" :to="{name: 'ShowCustomer', query: { partnerIdentifier: partnerIdentifier } }" >Cancel</v-btn>
      </v-col>
      <v-col cols="4" class="pl-1">
        <v-btn color="primary" :disabled="!valid" @click="submit" style="width: 100%;" >Save</v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="images.length>0">
      <v-col cols="12" class="pa-1">
        <Images :images="images" />
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';

  import Heading from '@/components/Heading';
  import TakePhoto from '@/components/TakePhoto';
  import Images from '@/components/Images';
  import TimeRangePicker from '@/components/TimeRangePicker';

  export default {

    components: { Heading, TakePhoto, Images, TimeRangePicker },

    data: () => ({
      uuid: uuidv4(),
      fieldId: null,
      changed: false,
      defaultRecommendation: null,
      subject: null,
      text: null,
      followUpAt: null,
      appointment: null,
      actions: [],
    }),

    computed: {
      targetFieldId() { return this.$route.query.fieldId; },
      partnerIdentifier() { return this.$route.query.partnerIdentifier; },
      customer() { return this.$store.getters.customer(this.partnerIdentifier); },
      fields() { return (this.customer.fields || []); },
      actionsList() { return (this.$store.getters.settings.standardRecommendations.actions || []); },
      annotations() { return (this.$store.getters.settings.standardRecommendations.annotations || []); },
      annotationsList() { return this.annotations.map(e => e.title); },
      showAnnotations() { return this.annotations.length>0; },
      cropLookups() { return this.$store.getters.cropLookups; },
      fieldList() { return this.fields.map(this.fieldItem) },
      images() {
        let i = this.$store.getters.images.filter( e => ( e.type_id===this.uuid ) && (e.type==='recommendation') );
        if (this.recommendation && this.recommendation.imageUrls) { i = i.concat(this.recommendation.imageUrls); }
        return i;
      },
      valid() {
        return this.changed && ((this.subject.length || '') > 3);
      }
    },

    watch: {
      targetFieldId: {
        immediate: true,
        handler(to) {
          if (to) {
            this.fieldId = parseInt(to);
          } else {
            this.fieldId = null;
          }
        },
      },
    },

    methods: {
      saveImage(uuid) { this.$store.dispatch('addImage', { type: 'recommendation', type_id: this.uuid, uuid: uuid }); },
      idImage(uuid) { this.$store.dispatch('addImage', { type: 'demographic', type_id: this.customer.partnerIdentifier, uuid: uuid }); },
      fieldItem(field) {
        let t = [];
        if (field.cropId) { t.push(this.cropLookups[field.cropId]); }
        if (field.fieldSize) {
          let size = '(' + field.fieldSize.landSize + ' ' + field.fieldSize.unit + ')'
          t.push(size);
        }
        return {
          value: field.id,
          text: t.join(' '),
        };
      },
      setDefault() {
        let recommendation = this.annotations.filter(e => e.title===this.defaultRecommendation)[0];
        if (recommendation) {
          this.subject = recommendation.subject;
          this.text = recommendation.text;
        }
      },
      submit() {
        let action = {
          createdAt: new Date(),
          partnerIdentifier: this.partnerIdentifier,
          fieldId: this.fieldId,
          subject: this.subject,
          text: this.text,
          followUpAt: this.followUpAt,
          appointment: this.appointment,
        }
        this.$store.dispatch('addFarmerAction', action);
        this.$router.push({ name: 'ShowCustomer', query: { partnerIdentifier: this.partnerIdentifier } });
      },
    }

  }
</script>
