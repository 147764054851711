<template>
  <v-form v-model="formValid">
    <PageHeader :title="$t('title')"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

      <v-row >

        <v-col cols="12" sm="4">
          <v-select :items="crops" :label="$t('crop')" class="text-left" v-model="cropId" :rules="[cropRules]"></v-select>
        </v-col>

        <v-col cols="4" sm="4">
          <v-text-field
            v-model="quantity"
            :rules="[quantityRules]"
            :label="$t('quantity')"
            type='number'
            suffix='Kg'
            min="0"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="4" sm="4">
          <v-card outlined>
            <v-card-title>
              <Currency :value="storageCosts" />
            </v-card-title>
            <v-card-subtitle>{{$t('storageCosts')}}</v-card-subtitle>
          </v-card>
        </v-col>

      </v-row>


    </PageHeader>
  </v-form>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import referenceNumbers from '@/mixins/referenceNumbers';

  import PageHeader from '@/components/PageHeader';
  import Currency from '@/components/Currency';

  export default {

    components: { PageHeader, Currency },

    data: () => ({
      formValid: false,

      cropId: undefined,

      deliveryNumber: undefined,
      quantity: 0,

    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      crops() { return this.$store.getters.crops; },
      cropLookups() { return this.$store.getters.cropLookups; },
      cropName() { return this.cropLookups[this.cropId]; },
      storageCosts() { return 15*this.quantity; },
      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    },

    methods: {
      cropRules(v) { return !!v || this.$t('required'); },
      quantityRules(v) {
        let r = true;
        if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
        if (parseFloat(v) > 2000000) { r = this.$t('maxExceeded'); }
        if (v.length==0) { r = this.$t('required') }
        return r;
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      save() {

        const delivery = {

          uuid: uuidv4(),
          createdAt: new Date().toISOString(),
          deliveryNumber: this.deliveryNumber,
          cropId: this.cropId,
          quantity: parseInt(this.quantity),
          storageCosts: this.storageCosts,
          cropName: this.cropName,

          customerUuid: this.customerUuid,
          location: this.location,
          capturedById: this.fieldAgentId,
        }
        
        this.$store.dispatch('addDelivery', delivery);

        this.$router.push({ name: 'ShowDelivery', query: { uuid: this.customerUuid, deliveryUuid: delivery.uuid } });
      },
    },

    mounted() {
      this.deliveryNumber = this.generateReferenceNumber()
    },

    mixins: [referenceNumbers],

  }
</script>


<i18n>
{
  "en": {
    "title": "Deliveries",
    "storageCosts": "Storage Costs",
    "minZero": "Quantity must be a positive number",
    "maxExceeded": "Quantity must be less than 2000000 kg"
  },
  "es": {
    "title": "Entregas",
    "storageCosts": "Costos de almacenamiento",
    "minZero": "La cantidad debe ser un número positivo",
    "maxExceeded": "La cantidad debe ser inferior a 2000000 kg."
  },
  "sw": {
    "title": "Wanaojifungua",
    "storageCosts": "Gharama za Uhifadhi",
    "minZero": "Wingi lazima iwe nambari nzuri",
    "maxExceeded": "Wingi lazima uwe chini ya 2000000kg"
  }
}
</i18n>
