<template>
  <v-text-field
    v-model="name"
    :rules="nameRules"
    :counter="computedCounter"
    :label="$t(label)"
     @input="handleInput"
  ></v-text-field>
</template>

<script>
  export default {
    props: ['value', 'label', 'counter'],
    data: () => ({
      name: null,
      nameRules: [
        // v => !!v || 'Required',
        v => /^.{0,20}$/.test(v) || 'Must be less than 20 characters',
        v => !(/[^\w-\s]/.test(v)) || 'Characters only',
        v => !(/\d/.test(v)) || 'No numbers',
        v => (v || '').length==0 || /^\S/.test(v) || 'No spaces at start',
        v => (v || '').length==0 || /\S$/.test(v) || 'No spaces at end',
      ],
    }),
    computed: {
      computedCounter() { return this.counter || 20; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) { this.name = to; }
      },
    },
    methods: {
      handleInput() {
        this.$emit('input', this.name);
      },
    },
  };
</script>


<i18n>
{
  "en": {
    "First Name": "First Name",
    "Surname": "Surname"
  },
  "es": {
    "First Name": "Primer nombre",
    "Surname": "Apellido"
  },
  "sw": {
    "First Name": "Jina la Kwanza",
    "Surname": "Jina la Pili"
  }
}
</i18n>
