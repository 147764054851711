<template>

  <v-container fluid class="pa-0">

    <v-data-table dense :headers="headers" :items="customers" item-key="id" show-select v-model="selected" >

      <template v-slot:item.id="{ item }">
        <v-btn small color="primary" @click="$emit('show', item)" >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.msisdn="{ item }">
        <Telephone :msisdn="item.msisdn" />
      </template>

      <template v-slot:item.identity_number="{ item }">
        <IdentityNumber :demographic="item.demographic" />
      </template>

      <template v-slot:item.location="{ item }">
        <LocationLink :location="item.location" />
      </template>

    </v-data-table>

  </v-container>



</template>


<script>

  import IdentityNumber from '@/components/IdentityNumber';
  import Telephone from '@/components/Telephone';
  import LocationLink from '@/components/LocationLink';

  export default {

    components: { IdentityNumber, Telephone, LocationLink },

    props: ['customers'],

    data: () => ({
      selected: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Telephone',
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'ID Number',
          align: 'start',
          sortable: true,
          value: 'identity_number',
        },
        {
          text: 'Location',
          align: 'start',
          sortable: false,
          value: 'location',
        },
        {
          align: 'right',
          value: 'id',
        },

      ],

    }),

    computed: {
      // search() { return this.$store.getters.search; },
    },

    watch: {
      selected(to) { this.$emit('selectionChange', to); },
    }

  }
</script>
