<template>
  <v-select
    label="Depot"
    :items="depots"
    item-value="id"
    item-text="name"
    v-model="depotId"
    @change="handleChange"
    :rules="[validate]"
  ></v-select>
</template>

<script>
  export default {
    props: {
      value: Object,
      required: { type: Boolean, default: false }
    },
    
    data: () => ({
      depotId: null,
    }),
    computed: {
      depots() { return this.$store.getters.settings.depots; },
      req() { return true; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.depotId = to.id;
          } else {
            this.depotId = null;
          }
        }
      },
    },
    methods: {
      validate(v) {
        if (this.required) {
          return (!!v || "Please select a depot");
        } else {
          return true;
        }
      },
      handleChange() { this.$emit('input', this.depots.filter( e => e.id===this.depotId )[0] ); },
    },
  };
</script>
