<template>
  <PageHeader title="PPTL"  >

    <v-container fluid >

      <v-row no-gutters >
        <v-col cols="12" class="text-left">
            <div id="app">
              <youtube video-id="eS0Sdx7AdqI" />
            </div>
        </v-col>
        <v-col cols="12" class="text-left">
            <div id="app">
              <youtube video-id="BIhNsAtPbPI" />
            </div>
        </v-col>
        <v-col cols="12" class="text-left">
            <div id="app">
              <youtube video-id="-JZ_moituIo" />
            </div>
        </v-col>
      </v-row>
    </v-container>


  </PageHeader>
</template>

<script>
  import PageHeader from '@/components/PageHeader';
  
  export default {

    components: { PageHeader },

    data: () => ({
    }),

    computed: {
    },

    methods: {
    },


  }
</script>


<i18n>
{
  "en": {
    "title": "Cash Receipt",
    "amount": "Amount",
    "receiptNumber": "Receipt Number",
    "receipted": "Receipted",
    "receiptedFrom": "Receipted From"
  },
  "es": {
    "title": "Recibo de caja",
    "amount": "Monto",
    "receiptNumber": "Número de recibo",
    "receipted": "Recibido en",
    "receiptedFrom": "Recibido desde"
  },
  "sw": {
    "title": "Stakabadhi ya Fedha",
    "amount": "Thamani",
    "receiptNumber": "Namba ya risiti",
    "receipted": "Kupokelewa Saa",
    "receiptedFrom": "Imepokewa Kutoka"
  }
}
</i18n>
