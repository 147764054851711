<template>
  <PageHeader :title="field.name || 'New Field'" :subtitle="customer.name" icon="mdi-crosshairs-gps" @subtitle="toCustomer" @back="toField" back  @save="save" save :valid="valid" >

    <v-container fluid class="pa-0">

      <v-row>
        <v-col class="text-caption">
          {{$t('info')}}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center" v-if="status=='updating'">
          <v-icon>mdi-crosshairs-gps</v-icon> Working...
        </v-col>
        <v-col class="text-center" v-else>
          <v-btn @click="recordLocation">
            <v-icon class="mr-1">mdi-crosshairs-gps</v-icon>
            {{$t('Record Location')}}
          </v-btn>
          <p class="red--text mt-2" v-if="status=='failed'">{{$t('Last read failed!')}}</p>
        </v-col>
      </v-row>

      <v-row v-if="accuracy">
        <v-col class="text-h5 text-center">
          <small>{{ $t(accuracy_description) }}</small>
          {{$t('Accuracy')}}:
          {{accuracy}} m
        </v-col>
      </v-row>

      <v-row v-if="error">
        <v-col>
          <v-alert type="error">
            {{$t('Unable to retrieve location. Please retry.')}}
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="showPlot">
        <v-col>
          <MapPlot :coords="locations" />
        </v-col>
      </v-row>

      <v-row v-if="area">
        <v-col>
          {{$t('Area')}}: {{area}} m<sup>2</sup> {{$t('or')}} {{hectares}} ha; {{$t('Perimeter')}}: {{perimeter}} m
        </v-col>
      </v-row>

      <v-row v-if="showTable">
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>{{$t('Location')}}</th>
                  <th>{{$t('Accuracy')}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pos in locations" :key="pos.index" >
                  <td class="text-left">{{ pos.index + 1 }}</td>
                  <td class="text-left"><LatLong :latitude="pos.latitude" :longitude="pos.longitude" /></td>
                  <td class="text-left">{{ pos.accuracy }} m</td>
                  <td class="text-center">
                     <a href="#" class="text-decoration-none" @click="delete_entry(pos.index)">
                      <v-icon small>mdi-delete</v-icon>
                     </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>
</template>


<script>
  var GeographicLib = require("geographiclib");
  // import GeographicLib from "geographiclib";

  import PageHeader from '@/components/PageHeader';
  import MapPlot from '@/components/MapPlot';
  import Heading from '@/components/Heading';
  import LatLong from '@/components/LatLong';
  
  export default {

    components: { PageHeader, MapPlot, Heading, LatLong },

    data: () => ({
      status: 'ready',
      locations: [],
      error: null,
      accuracy: null,
      accuracy_description: 'Initial',
      timer: null,
      area: null,
      perimeter: null,
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },

      customer() { return this.$store.getters.customer(this.customerUuid); },
      field() { return this.customer.fields.filter( f => f.uuid==this.fieldUuid )[0]; },

      fieldNumber() { return 0; },

      showTable() { return !(this.error || this.locations.length==0); },
      showPlot() { return !(this.error || this.locations.length<2); },

      hectares() { return (this.area ? (this.area/10000).toFixed(2) : null); },

      valid() { return this.locations.length>=3; },
    },

    methods: {


      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      toField() { this.$router.push({ name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid } }); },

      getLocation(callback) {
        this.status = 'updating';
        let options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(pos => {
          if (pos.coords) {
            let coords = {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              accuracy: pos.coords.accuracy,
              altitude: pos.coords.altitude,
              altitudeAccuracy: pos.altitudeAccuracy,
            };
            callback(coords);
            this.status = 'ready';
          } else {
            this.status = 'failed';
          }
        }, err => {
          this.status = 'failed';
        }, options);
      },

      recordLocation() {
        this.getLocation(coords => {
          let indexes = this.locations.map( e => e.index );
          if (indexes.length == 0) {
            coords.index = 0;
          } else {
            coords.index = Math.max(...this.locations.map( e => e.index )) + 1;
          }
          if (process.env.NODE_ENV === 'production') {
            coords.latitude = coords.latitude;
            coords.longitude = coords.longitude;
          } else {
            coords.latitude = coords.latitude + Math.random()/1000.0 - 0.0005;
            coords.longitude = coords.longitude + Math.random()/1000.0 - 0.0005;
          }
          this.locations.push(coords);
          this.accuracy = coords.accuracy;
          this.accuracy_description = 'Last';
        });
      },

      refreshAccuracy() {
        this.getLocation(coords => {
          this.accuracy = coords.accuracy;
          this.accuracy_description = 'Current';
        });
      },

      delete_entry(index) {
        let i = this.locations.findIndex( e => e.index===index );
        this.locations.splice(i, 1);
      },

      midpoint() {
        let latitude = 0;
        let longitude = 0;
        let count = 0;
        this.locations.forEach( e => { latitude = latitude + e.latitude; longitude = longitude + e.longitude; count = count + 1 } );
        return { latitude: latitude/count, longitude: longitude/count };
      },

      save() {
        this.field.map = this.locations;
        if (this.field.location==null) { this.field.location = this.midpoint(); }
        this.field.measured_m2 = this.area;
        this.field.perimeter = this.perimeter;
        this.$store.dispatch('upsertCustomer', this.customer);
        this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid }});
      },

      calculateArea() {
        if (this.locations.length>2) {
          let Geodesic = GeographicLib.Geodesic;
          let geod = Geodesic.WGS84;
          let lat_longs = this.locations.map( e => [e.latitude, e.longitude] );
          let p = geod.Polygon(false);
          for (let i = 0; i < lat_longs.length; ++i) { p.AddPoint(lat_longs[i][0], lat_longs[i][1]); }
          p = p.Compute(false, true);
          this.area = Math.abs(p.area.toFixed(0));
          this.perimeter = p.perimeter.toFixed(0);
        } else {
          this.area = null;
          this.perimeter = null;
        }
      },

    },

    watch: {
      locations: function() {
        this.calculateArea();
      },
    },

    mounted() {
      this.refreshAccuracy();
      this.timer =  setInterval(() => { this.refreshAccuracy(); }, 30000)
    },

    beforeDestroy() {
      clearInterval(this.timer)
    },

  };
</script>


<i18n>
{
  "en": {
    "Record Location": "Record Location",
    "Accuracy": "Accuracy",
    "Location": "Location",
    "Area": "Area",
    "or": "or",
    "Perimeter": "Perimeter",
    "Last read failed!": "Last read failed!",
    "Unable to retrieve location. Please retry.": "Unable to retrieve location. Please retry.",
    "info": "Walk around the area you wish to measure. At each corner, tap here to record the location.",
    "Initial": "Initial",
    "Current": "Current",
    "Last": "Last"
  },
  "es": {
    "Record Location": "Ubicación de Registro",
    "Accuracy": "Precisión",
    "Location": "La Ubicación",
    "Area": "Municipio",
    "or": "o",
    "Perimeter": "Perímetro",
    "Last read failed!": "¡Última lectura fallida!",
    "Unable to retrieve location. Please retry.": "No se pudo recuperar la ubicación. Por favor, intenta de nuevo.",
    "info": "Camine por el área que desea medir. En cada esquina, toque aquí para registrar la ubicación.",
    "Initial": "Inicial",
    "Current": "Actual",
    "Last": "Último"
  },
  "sw": {
    "Record Location": "Rekodi Mahali",
    "Accuracy": "Usahihi",
    "Location": "Mahali",
    "Area": "Eneo",
    "or": "au",
    "Perimeter": "Mzunguko",
    "Last read failed!": "Kusoma mwisho kulishindwa!",
    "Unable to retrieve location. Please retry.": "Imeshindwa kupata eneo. Tafadhali jaribu tena.",
    "info": "Tembea karibu na eneo unalotaka kupima. Kwenye kila kona, gonga hapa kurekodi eneo.",
    "Initial": "Awali",
    "Current": "Sasa",
    "Last": "Mwisho"
  }
}
</i18n>
