<template>

  <v-text-field
    v-model="landSize"
    :rules="[landSizeRules]"
    :label="$t(label)"
    min=0
    type='number'
    @input="handleInput"
  >
    <template v-slot:append-outer>
      <v-select
        v-model="unit"
        :items="units"
        dense
        :rules="[landSizeUnitRules]"
        @input="handleInput"
      ></v-select>
    </template>
  </v-text-field>

</template>

<script>
  export default {
    props: {
      value: Object,
      label: String,
      required: { type: Boolean, default: false },
    },
    data: () => ({
      landSize: 0,
      unit: 'Hectares',
      units: ['Hectares', 'Acres'],
    }),
    computed: {
      
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          const t = to || {};
          this.landSize = t['landSize'] || 0;
          this.unit = t['unit'] || 'Hectares';
        },
      },
      // landSizeUnit: function(to) { this.$store.dispatch('setDefault', { landSizeUnit: to } ); },
    },
    methods: {
      handleInput() {
        this.$emit('input', { 'landSize': this.landSize, 'unit': this.unit });
      },
      landSizeRules(value) {
        let r = (/^[0123456789\.]*$/.test(value));
        if (r) { r = (parseFloat(value) >= 0); }
        if (r && this.required) { r = (parseFloat(value) > 0); }
        if (r) {
          r = ((this.unit || '').length > 0) || this.$t('unitError');
        } else {
          r = this.$t('positiveNumber');
        }
        return r;
      },
      landSizeUnitRules(value) { return ((value || '').length>0) || parseFloat(this.landSize)==0 || this.$t('required');  },
    }

  };
</script>


<i18n>
{
  "en": {
    "Field size": "Field size",
    "Land size": "Land size",
    "unitError": "Please define the unit'",
    "required": "Required",
    "positiveNumber": "Enter a positive number"
  },
  "es": {
    "Field size": "Tamaño del campo",
    "Land size": "Tamaño de la tierra",
    "unitError": "Por favor defina la unidad",
    "required": "Requerida",
    "positiveNumber": "Ingrese un número positivo"
  },
  "sw": {
    "Field size": "Ukubwa wa shamba",
    "Land size": "Ukubwa wa ardhi",
    "unitError": "Tafadhali fafanua kitengo",
    "required": "Inahitajika",
    "positiveNumber": "Ingiza nambari chanya"
  }
}
</i18n>
