<template>
  <span>
    <small class="hidden-xs-only">{{currency}}</small>
    <NumberFormatter :value="value" />
  </span>
</template>



<script>
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { NumberFormatter },

    props: ['value'],

    computed: {
      currency() { return this.$store.getters.currency; },
    },

  }
</script>
