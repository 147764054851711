<template>
  <v-form v-model="formValid">
    <PageHeader :title="$t('title')"  save @save="save" :valid='formValid' icon="mdi-pine-tree" >

      <template v-slot:buttons>
        <TakePhoto @click="saveImage">
          <v-btn color="primary" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-outline</v-icon> <span class="hidden-sm-and-down">{{$t('photo')}}</span></v-btn>
        </TakePhoto>
      </template>


      <template v-slot:menu>
        <TakePhoto  :text="$t('imageInfo')" @click="saveImage">
          <AppMenuItem icon="mdi-camera-outline" :title="$t('Take Photos')" />
        </TakePhoto>
      </template>

      <v-container fluid>

        <v-row class="blue lighten-5">
          <v-col cols="12" sm="6" >
            <v-text-field
              v-model="identifier"
              :rules="identifierRules"
              :label="$t('Identifier')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" >
            <v-select
              v-model="theSpecies"
              :items="species"
              :label="$t('species')"
              single-line
              :rules="[(v) => !!v || 'Required']"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="red lighten-5">
          <RegionPicker v-model="regions" />
        </v-row>

        <v-row class="green lighten-5">
          <v-col cols="12" sm="3" >
            <v-text-field v-model="height" :rules="[measurementRules]" :label="$t('height')" min=0 type='number' :hint="$t('inMeters')" />
          </v-col>
          <v-col cols="12" sm="3" >
            <v-text-field v-model="girth" :rules="[measurementRules]" :label="$t('girth')" min=0 type='number' :hint="$t('girth_hint')" />
          </v-col>
          <v-col cols="12" sm="3" >
            <v-text-field v-model="crown" :rules="[measurementRules]" :label="$t('crown')" min=0 type='number' :hint="$t('inMeters')" />
          </v-col>
          <v-col cols="12" sm="3" >
            <v-text-field v-model="volume" :rules="[measurementRules]" :label="$t('volume')" min=0 type='number' :hint="$t('inMeters')" />
          </v-col>
        </v-row>

      </v-container>

    </PageHeader>
  </v-form>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';

  import PageHeader from '@/components/PageHeader';
  import NameInput from '@/components/PageHeader';
  import RegionPicker from '@/components/RegionPicker';
  import TakePhoto from '@/components/TakePhoto';
  import AppMenuItem from '@/components/AppMenuItem';

  export default {

    components: { PageHeader, NameInput, RegionPicker, TakePhoto, AppMenuItem },

    data: () => ({
      formValid: false,
      amount: 0,

      uuid: undefined,
      identifier: undefined,
      regions: undefined,
      theSpecies: undefined,

      height: undefined,
      girth: undefined,
      crown: undefined,
      volume: undefined,

      species: [
        'Andean Wax Palm',
        'American Mahogany',
        'Big-leaf Mahogany',
        'Magnolio de Jardin',
        'Quindío wax palm',
      ],

      identifierRules: [
        // v => !!v || 'Required',
        v => /^.{0,20}$/.test(v) || 'Must be less than 20 characters',
        v => (v || '').length==0 || /^\S/.test(v) || 'No spaces at start',
        v => (v || '').length==0 || /\S$/.test(v) || 'No spaces at end',
      ],


    }),

    computed: {
      location() {
        let coords = undefined;
        const location = this.$store.getters.geolocation;
        if (location) {
          coords = {
            latitude:  location.coords.latitude,
            longitude: location.coords.longitude,
          }
        };
        return coords;
      },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    },

    methods: {
      measurementRules(v) {
        let r = true;
        if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
        if (parseFloat(v) > 100) { r = this.$t('maxExceeded'); }
        return r;
      },
      saveImage(uuid) { this.$store.dispatch('addImage', { type: 'tree', type_id: this.uuid, uuid: uuid }); },
      save() {

        const tree = {
          uuid: this.uuid,
          createdAt: new Date().toISOString(),

          identifier: this.identifier,
          regions: this.regions,
          species: this.theSpecies,

          height: this.height,
          girth: this.girth,
          crown: this.crown,
          volume: this.volume,

          location: this.location,
          capturedById: this.fieldAgentId,
        }

        this.$store.dispatch('addTree', tree);

        this.$router.push({ name: 'Trees' });
      },
    },

    mounted() {
      this.uuid = uuidv4();
      this.identifier = this.uuid.substring(0,5);
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "New Tree",
    "Identifier": "Identifier",
    "girth_hint": "Girth at 1.3m above ground (DBH). Meters.",
    "inMeters": "In meters"
  },
  "es": {
    "title": "Árbol nuevo",
    "Identifier": "Identificador",
    "girth_hint": "Circunferencia a 1.3 m sobre el suelo (DBH). Metros.",
    "inMeters": "En metros"
  },
  "sw": {
    "title": "Mti Mpya",
    "Identifier": "Kitambulisho",
    "girth_hint": "Girth kwa 1.3m juu ya ardhi (DBH). Mita.",
    "inMeters": "Katika mita"
  }
}
</i18n>
