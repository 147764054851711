<template>
  <span v-if="!(value===undefined || value===null)">
    {{ formatted(value) }}
    <small v-if="unit">{{unit}}</small>
  </span>
</template>


<script>

  export default {

    props: ['value', 'unit'],

    computed: {
      countryCode() { return this.$store.getters.settings.countryCode; },
    },

    methods: {
      formatted(amount) {
        if (this.countryCode==='TZ') {
          return this.tz(amount);
        } else {
          return this.default(amount);
        }
      },
      default(amount) {
        let a = parseFloat(amount);
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
      },
      tz(amount) {
        let a = parseInt(amount);
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
    },

  }
</script>
