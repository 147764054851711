<template>
  <v-container fluid class="pa-0">

    <v-row v-if="customersInitialised">
      <v-col cols=12>

        <div v-if="customersInitialised && customers.length==0">
          <div class="mb-2">
            {{$t('noData')}}
          </div>
          <div>
            <v-btn elevation="2" small :to="{ name: 'CreateContact' }" color="primary">Create One</v-btn>
          </div>
        </div>
        
        <div class="pa-0 ma-0" v-else>

          <v-tabs v-model="tab" class="px-0">
            <v-tab v-if="farmers.length>0">
              <v-icon left>mdi-account-settings</v-icon> {{$t('farmers')}}
            </v-tab>
            <v-tab v-if="contacts.length>0">
              <v-icon left>mdi-account-question</v-icon> {{$t('contacts')}}
            </v-tab>
            <v-tab v-if="agents.length>0">
              <v-icon left>mdi-teach</v-icon> {{$t('agents')}}
            </v-tab>
            <v-tab>
              <v-icon left>mdi-account</v-icon> {{$t('all')}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-if="farmers.length>0"><Farmers :farmers="farmers" /></v-tab-item>
            <v-tab-item v-if="contacts.length>0"><Contacts :contacts="contacts" /></v-tab-item>
            <v-tab-item v-if="agents.length>0"><Customers :custmoers="agents" /></v-tab-item>
            <v-tab-item><Customers :customers="customers" /></v-tab-item>
          </v-tabs-items>

        </div>

      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-else>
      <v-col cols=3 />
      <v-col cols=6 align="center" justify="center" >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col cols=3 />
    </v-row>

  </v-container>
</template>


<script>

  import Customers from '@/views/customers/components/Index';
  import Farmers from '@/views/farmers/components/Index';
  import Contacts from '@/views/contacts/components/Index';

  export default {

    components: {
      Customers,
      Farmers,
      Contacts,
    },

    data: () => ({
      tab: null,
    }),

    computed: {
      customersInitialised() { return this.$store.getters.customersInitialised; },
      customers() { return this.$store.getters.customers; },
      farmers() { return this.$store.getters.farmers; },
      contacts() { return this.$store.getters.contacts; },
      agents() { return this.$store.getters.agents; },
    },

  }
</script>


<i18n>
{
  "en": {
    "noData": "There are no farmers, contacts or other profiles saved locally.",
    "farmers": "Farmers",
    "contacts": "Contacts",
    "agents": "Agents",
    "all": "All"
  },
  "es": {
    "noData": "No hay agricultores, contactos u otros perfiles guardados localmente.",
    "farmers": "Agricultores",
    "contacts": "Contactos",
    "agents": "Técnico Agrícola",
    "all": "Todos"
  },
  "sw": {
    "noData": "Hakuna mkulima, mawasiliano au wasifu mwingine uliookolewa ndani.",
    "farmers": "Wakulima",
    "contacts": "Mawasiliano",
    "agents": "Mawakala",
    "all": "Kila Mtu"
  }
}
</i18n>

