<template>
  <v-radio-group v-model="gender" row dense>
    <v-radio :label="$t('male')"   value="male"></v-radio>
    <v-radio :label="$t('female')" value="female"></v-radio>
  </v-radio-group>
</template>


<script>
  export default {

    props: ['value'],

    data: () => ({
      gender: false,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.gender = to; }
      },
      gender(to) { this.$emit('input', to); },
    },


  }
</script>


<i18n>
{
  "en": {
    "male": "Male",
    "female": "Female"
  },
  "es": {
    "male": "Hombre",
    "female": "Mujer"
  },
  "sw": {
    "male": "Mwanaume",
    "female": "Mwanamke"
  }
}
</i18n>
