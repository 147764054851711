var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.farmers,"item-key":"item.uuid","search":_vm.search},scopedSlots:_vm._u([{key:"header.firstName",fn:function(){return [_vm._v(_vm._s(_vm.$t('firstName')))]},proxy:true},{key:"header.surname",fn:function(){return [_vm._v(_vm._s(_vm.$t('surname')))]},proxy:true},{key:"header.partnerIdentifier",fn:function(){return [_vm._v(_vm._s(_vm.$t('partnerIdentifier')))]},proxy:true},{key:"header.msisdn",fn:function(){return [_vm._v(_vm._s(_vm.$t('telephone')))]},proxy:true},{key:"header.identity_number",fn:function(){return [_vm._v(_vm._s(_vm.$t('idNumber')))]},proxy:true},{key:"header.location",fn:function(){return [_vm._v(_vm._s(_vm.$t('location')))]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'ShowCustomer', query: { uuid: item.uuid } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-account")])],1)]}},{key:"item.partnerIdentifier",fn:function(ref){
var item = ref.item;
return [(item.partnerIdentifier)?_c('PartnerIdentifier',{attrs:{"identifier":item.partnerIdentifier}}):_vm._e()]}},{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.identity_number",fn:function(ref){
var item = ref.item;
return [_c('IdentityNumber',{attrs:{"demographic":item.demographic}})]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('LocationLink',{attrs:{"location":item.location,"short":true}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }