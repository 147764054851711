<template>
  <v-container fluid @click="showDebug">

    <v-row v-if="!validPoints">
      <v-col>
        <v-alert type="error">
          Points are too close togehter. Please repeat.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="validPoints">
      <v-col>
        <svg :height="box_height" width="100%" :viewBox="viewBox">
          <polygon :points="points" style="fill:#B08136;stroke:#BF8C3B;stroke-width:1" />
        </svg>
      </v-col>
    </v-row>

    <v-row v-if="debug">
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>viewBox</td>
                <td>{{viewBox}}</td>
              </tr>
              <tr>
                <td>valid</td>
                <td>{{validPoints}}</td>
              </tr>
              <tr>
                <td>points</td>
                <td>{{points}}</td>
              </tr>
              <tr>
                <td>scaled</td>
                <td>{{scaled}}</td>
              </tr>
              <tr>
                <td>scaleFactor</td>
                <td>{{scaleFactor}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

  // longitude ==> x

  export default {
    props: ['coords', 'height'],

    data: () => ({
      debug: false,
    //   coords: [
    //     {
    //       latitude: -33.95276661965433,
    //       longitude: 18.480431931110544,
    //     },
    //     {
    //       latitude: -33.95279406503892,
    //       longitude: 18.480551854065176,
    //     },
    //     {
    //       latitude: -33.95270203709039,
    //       longitude: 18.480512648838086,
    //     },
    //   ]
    }),


    computed: {
      box_height() { return this.height || '200'; },

      transformed() { return this.coords.map(e => [-1*e.latitude, e.longitude]); },
      min_x() { return Math.min(...this.transformed.map(e => e[0])); },
      max_x() { return Math.max(...this.transformed.map(e => e[0])); },
      min_y() { return Math.min(...this.transformed.map(e => e[1])); },
      max_y() { return Math.max(...this.transformed.map(e => e[1])); },
      adjusted() { return this.transformed.map(e => [ (e[0]-this.min_x), (e[1]-this.min_y) ]) },
      scaleFactor() { return Math.max( ...this.adjusted.flat() ); },
      scaled() { return this.adjusted.map( e => [ Math.round((e[0]/this.scaleFactor)*100.0), Math.round((e[1]/this.scaleFactor)*100.0) ] ) },
      validPoints() { return this.scaled.filter(e => ( isNaN(e[0]) || isNaN(e[1]) ) ).length==0; },
      points() { return this.scaled.map(e => e.join(',')).join(' '); },
      viewBox() { return '0 0 100 100'; }  // scaling to out of 100, so this should work.
    },

    methods: {
      showDebug() {
        // this.debug = true;
      },
    }
  };

</script>
