<template>
  <PageHeader :title="title" :icon="icon" back @back="$emit('back')" print @print="print" >
    <div id="printTarget">
      <slot></slot>
    </div>
  </PageHeader>
</template>

<script>

import PageHeader from '@/components/PageHeader';

export default {

  components: { PageHeader },

  props: ['title', 'icon'],

  methods: {
    async print() { await this.$htmlToPaper('printTarget'); },
  }

};
</script>
