<template>
  <v-icon title="Connected" color="primary" dense v-if="isOnline">mdi-access-point-network</v-icon>
  <v-icon title="Disconnected" color="primary" dense v-else>mdi-access-point-network-off</v-icon>
</template>

<script>
  import Ping from 'ping.js';
  export default {
    data: () => ({
      isOnline: navigator.onLine || false,
    }),
    created() { this.updateOnlineStatus(); },
    methods: {
      updateOnlineStatus() {
        const t = this;
        const p = new Ping();
        p.ping('https://google.com', (err) => {
          if (err) {
            t.isOnline = false;
          } else {
            t.isOnline = true;
          }
        });
      },
    },
  };
</script>
