<template>

  <v-data-table dense :headers="headers" :items="contacts" item-key="item.uuid" :search="search" >

    <template v-slot:item.visitDate="{ item }">
      <Time v-if="item.visitDate" :time="item.visitDate" format="date" />
      <v-chip v-else small >
        Not Set
      </v-chip>
    </template>

    <template v-slot:item.msisdn="{ item }">
      <Telephone :msisdn="item.msisdn" />
    </template>

    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: item.uuid } }" class="mr-2" >
        <v-icon dense small>mdi-account</v-icon>
      </v-btn>
      <v-btn small color="primary" >
        <v-icon dense small>mdi-pencil</v-icon>
      </v-btn>
    </template>

  </v-data-table>

</template>


<script>

  import Time from '@/components/Time';
  import Telephone from '@/components/Telephone';

  export default {

    components: {
      Time,
      Telephone,
    },

    props: ['contacts'],

    data: () => ({
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'firstName',
        },
        {
          text: 'Surname',
          align: 'start',
          sortable: true,
          value: 'surname',
        },
        {
          text: 'Telephone',
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Visit',
          align: 'start',
          sortable: true,
          value: 'visitDate',
        },
        {
          text: '',
          align: 'center',
          value: 'id',
        },

      ],

    }),

    computed: {
      search() { return this.$store.getters.search; },
    },

    methods: {
    },

  }
</script>
