<template>
  <span :title="title" @click="changeLocale" class="px-1 py-0 text--blue">
    {{ locale.toUpperCase() }}
  </span>
</template>

<script>
export default {
  computed: {
    locale() { return this.$i18n.locale; },
    title() {
      let t = {
        en: 'English',
        es: 'Español',
        sw: 'Swahili',
      }[this.locale];
      return t;
    }
  },
  methods: {
    changeLocale() {
      const locales = ['en', 'sw', 'es'];
      let current = this.locale;
      let i = locales.findIndex(e => e==current ) + 1;
      if (i==locales.length) { i = 0; }
      this.$i18n.locale = locales[i];
    }
  }
};
</script>
