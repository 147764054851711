<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="yellow lighten-5">
        <v-col cols="12" sm="6">
          <TakePhoto>
            <v-btn color="warning" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon> {{$t('Certificate of Registration')}}</v-btn>
          </TakePhoto>
        </v-col>
        <v-col cols="12" sm="6">
          <TakePhoto>
            <v-btn color="warning" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon> {{$t('TIN Number')}}</v-btn>
          </TakePhoto>
        </v-col>
        <v-col cols="12" sm="6">
          <TakePhoto>
            <v-btn color="warning" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon> {{$t('VAT Certificate')}}</v-btn>
          </TakePhoto>
        </v-col>
        <v-col cols="12" sm="6">
          <TakePhoto>
            <v-btn color="warning" small dense class="ml-1"><v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon> {{$t('Offices')}}</v-btn>
          </TakePhoto>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>

  import TakePhoto from '@/components/TakePhoto';

  export default {

    components: {
      TakePhoto,
    },

    data: () => ({
      customer: {},
      validForm: false,
    }),


    computed: {
      crops() { return this.$store.getters.cropNames; },
    },

  }
</script>


<i18n>
{
  "en": {
    "Business Name": "Business Name",
    "Business TIN": "Business TIN",
    "Primary Crop": "Primary Crop",
    "Secondary Crop": "Secondary Crop",
    "First Name": "Director First Name",
    "Surname": "Director Surname",
    "Notes": "Notes",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear"
  },
  "es": {
    "Business Name": "Business Name",
    "Business TIN": "Business TIN",
    "Primary Crop": "Cultivo Principal",
    "Secondary Crop": "Secondary Crop",
    "First Name": "Primer nombre",
    "Surname": "Apellido",
    "Notes": "Notas",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
  },
  "sw": {
    "Business Name": "Business Name",
    "Business TIN": "Biashara TIN",
    "Primary Crop": "Mazao ya Msingi",
    "Secondary Crop": "Mazao ya Sekondari",
    "First Name": "Mkurugenzi jina la kwanza",
    "Surname": "Jina la mkurugenzi",
    "Notes": "Noti",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Wazi"
  }
}
</i18n>
