<template>
  <span>
    <img v-if="logo=='sasa'" src="@/assets/logo.png" />
    <img v-if="logo=='hr_co'" src="@/assets/hr_co.gif" />
    <img v-if="logo=='winwin_zw'" src="@/assets/winwin_zw.jpg" />
    <img v-if="logo=='apex_tz'" src="@/assets/apex_tz.png" />
  </span>
</template>

<script>
  export default {
    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      logo() {
        let l = 'sasa';
        if (['apex_tz', 'hr_co', 'winwin_zw'].includes(this.partnerCode)) {
          l = this.partnerCode;
        }
        return l;
      }
    },
  };
</script>
