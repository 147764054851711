<template>
  <v-app :style="{background: '#F5F5F5'}">

    <HeaderBar v-if="live" displaySearchOnRoutes="Customers, SkuIndex, NewInputOrder" />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <Welcome v-if="userState=='logged_out'" />
      <Live v-if="live" />
    </v-main>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      {{$t('updateAvailable')}}
      <v-btn text @click="refreshApp">
        {{$t('update')}}
      </v-btn>
    </v-snackbar>

    <FooterBar v-if="live" />

  </v-app>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>


<script>

  import pusher from '@/mixins/pusher'
  import updatePwa from '@/mixins/updatePwa'

  import HeaderBar from '@/components/HeaderBar';
  import FooterBar from '@/components/FooterBar';
  import Welcome from '@/views/login/Welcome';
  import Live from '@/views/login/Live'

  import '@/filters.js';

  export default {
    name: 'App',

    components: { HeaderBar, FooterBar, Welcome, Live },

    data: () => ({}),

    computed: {
      userState() {
        let s = 'logged_out';
        if (this.$store.getters.authenticated)  { s = 'live'; }
        return s;
      },
      live() { return (this.userState=='live'); },
    },

    mounted() { this.$store.dispatch('updateGeolocation'); },

    mixins: [updatePwa, pusher],

  };
</script>


<i18n>
{
  "en": {
    "update": "Update",
    "updateAvailable": "An update is available"
  },
  "es": {
    "update": "Actualizar",
    "updateAvailable": "Hay una actualización disponible"
  },
  "sw": {
    "update": "Sasisha",
    "updateAvailable": "Sasisho linapatikana"
  }
}
</i18n>
