<template>
  <v-dialog
    v-model="dialog"
    width="350px"
    style="z-index: 9999"
  >
    <template v-slot:activator="{ on, attrs }">
      <span @click.stop="dialog = true">
        <slot></slot>
      </span>
    </template>

    <v-card>

      <v-img :src="url" height="300px" v-if="url"></v-img>

      <v-card-subtitle class="pt-4" v-if="!image">
        <span v-if="text">{{text}}</span>
        <span v-else>{{$t('message')}}</span>
      </v-card-subtitle>

      <v-card-actions>

        <v-file-input class="d-none" label="File input" outlined dense capture="user" accept="image/*" @change="cameraClick" v-model="image" ref="fileInput" ></v-file-input>

        <v-btn color="deep-purple lighten-2" text @click="triggerTake" >
          <span v-if="image">{{$t('retake')}}</span>
          <span v-else>{{$t('takePhoto')}}</span>
        </v-btn>

        <v-spacer />

        <v-btn color="deep-purple lighten-2" text>
          <span v-if="image" @click="save">{{$t('save')}}</span>
          <span v-else @click="cancel">{{$t('cancel')}}</span>
        </v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
  import ImageDb from '@/store/ImageDb';

  export default {
    props: ['text'],
    data () {
      return {
        dialog: false,
        url: null,
        image: null,
      }
    },
    computed: {
      apiKey() { return this.$store.getters.apiKey; },
    },
    methods: {
      cameraClick() { this.url = URL.createObjectURL(this.image); },
      triggerTake() { this.$refs.fileInput.$refs.input.click(); },
      cancel() {
        this.dialog = false;
        this.image = null;
        this.url = null;
      },
      save() {
        const _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = function () {
          let uuid = ImageDb.add(reader.result);
          _this.$emit('click', uuid);
          _this.image = null;
          _this.url = null;
          _this.dialog = false;
        };
      },
    },
  }
</script>


<i18n>
{
  "en": {
    "message": "Use your camera to take a photo. Then, if you are happy with the photo, click save. It will be stored locally and uploaded when you next have an internet connection.",
    "retake": "Retake",
    "takePhoto": "Take Photo"
  },
  "es": {
    "message": "Usa tu cámara para tomar una foto. Luego, si está satisfecho con la foto, haga clic en guardar. Se almacenará localmente y se cargará la próxima vez que tenga una conexión a Internet.",
    "retake": "Retomar",
    "takePhoto": "Tomar Foto"
  },
  "sw": {
    "message": "Tumia kamera yako kuchukua picha. Kisha, ikiwa unafurahi na picha hiyo, bonyeza kuokoa. Itahifadhiwa kienyeji na kupakiwa wakati ujao una unganisho la mtandao.",
    "retake": "Kurudia",
    "takePhoto": "Piga Picha"
  }
}
</i18n>

