<template>
  <v-app-bar dense app color="info">

    <Logo class="hidden-xs-only" />

    <v-spacer></v-spacer>

    <template v-if="live">
      <SearchBox :displaySearchOnRoutes="displaySearchOnRoutes" class="mr-2" />
      <HeaderBarRoutes />
    </template>

  </v-app-bar>

</template>

<script>
import SearchBox from '@/components/SearchBox';
import HeaderBarRoutes from '@/components/HeaderBarRoutes';
import Logo from '@/components/HeaderBarLogo';


export default {
  components: { SearchBox, HeaderBarRoutes, Logo },
  props: ['displaySearchOnRoutes'],
  computed: {
    live() { return this.$store.getters.authenticated; },
  },
  methods: {
    logout() { this.$store.dispatch('logout'); },
  }
};
</script>

