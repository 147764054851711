<template>
  <v-select
    label="Field Agent"
    :items="fieldAgents"
    item-value="id"
    item-text="name"
    v-model="fieldAgentId"
    @change="handleChange"
  ></v-select>
</template>

<script>
  export default {
    props: ['value'],
    data: () => ({
      fieldAgentId: null,
    }),
    computed: {
      fieldAgents() { return this.$store.getters.settings.fieldAgents; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.fieldAgentId = to.id;
          } else {
            this.fieldAgentId = null;
          }
        }
      },
    },
    methods: {
      handleChange() { this.$emit('input', this.fieldAgents.filter( e => e.id===this.fieldAgentId )[0] ); },
    },
  };
</script>
