<template>
  <PageHeader :title="$t('trees')" icon="mdi-pine-tree" >

    <template v-slot:buttons>
      <v-btn color="primary" small dense class="mr-1" :to="{name: 'RegisterTree' }"><v-icon dense>mdi-pine-tree</v-icon> {{$t('registerTree')}}</v-btn>
      <v-btn color="primary" small dense class="mr-1" :to="{name: 'TreeFinder' }"><v-icon dense>mdi-image-search-outline</v-icon> {{$t('search')}}</v-btn>
    </template>

    <template v-slot:menu>
      <AppMenuItem icon="mdi-pine-tree" :to="{name: 'RegisterTree' }" :title="$t('registerTree')" />
    </template>


    <v-data-table
      :headers="headers"
      :items="trees"
      :items-per-page=15
      class="elevation-1"
      style="width: 100%;"
    >

      <template v-slot:item.identifier="{ item }">
        <code>{{item.identifier}}</code>
      </template>

      <template v-slot:item.species="{ item }">
        <i>{{item.species}}</i>
      </template>
      <template v-slot:item.height="{ item }">
        <NumberFormatter :value='parseFloat(item.height)' unit="m" />
      </template>
      <template v-slot:item.girth="{ item }">
        <NumberFormatter :value='parseFloat(item.girth)' unit="m" />
      </template>
      <template v-slot:item.crown="{ item }">
        <NumberFormatter :value='parseFloat(item.crown)' unit="m" />
      </template>
      <template v-slot:item.volume="{ item }">
        <NumberFormatter :value='parseFloat(item.volume)' unit="m" />
      </template>


    </v-data-table>
  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader';
  import AppMenuItem from '@/components/AppMenuItem';
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { PageHeader, AppMenuItem, NumberFormatter },

    data: () => ({

    }),

    computed: {
      trees() { return (this.$store.getters.trees || []); },
      headers() { return [
        {
          sortable: true,
          value: 'identifier',
          text: this.$t('identifier'),
        },
        {
          sortable: true,
          value: 'species',
          text: this.$t('species'),
        },
        {
          align: 'right',
          sortable: true,
          value: 'height',
          text: this.$t('height'),
        },
        {
          align: 'right',
          sortable: true,
          value: 'girth',
          text: this.$t('girth'),
        },
        {
          align: 'right',
          sortable: true,
          value: 'crown',
          text: this.$t('crown'),
        },
        {
          align: 'right',
          sortable: true,
          value: 'volume',
          text: this.$t('volume'),
        },
      ]; },
    },

  }
</script>


<i18n>
{
  "en": {
    "identifier": "Identifier",
    "registerTree": "Register Tree"
  },
  "es": {
    "identifier": "Identificador",
    "registerTree": "Árbol de registro"
  },
  "sw": {
    "identifier": "Kitambulisho",
    "registerTree": "Sajili Mti"
  }
}
</i18n>
