<template>
  <v-container fill-height fluid>

    <v-row align="center" justify="center">
      <v-col cols=1 />
      <v-col cols=10>

      <v-alert dense outlined type="error" v-model="problem" icon="mdi-emoticon-sad-outline" dismissible >
         {{$t('message')}}
      </v-alert>

        <Identifier v-model="identifier"                   v-if="identifier===undefined" />
        <v-progress-circular indeterminate color="primary" v-else-if="key===undefined" />
        <Otp v-model="otp"  :identifier="identifier"       v-else-if="otp===undefined" />
        <v-progress-circular indeterminate color="primary" v-else-if="otp" />

        <Restart class="mt-2" v-if="identifier" @restart="restart()" />

      </v-col>
      <v-col cols=1 />
    </v-row>

  </v-container>
</template>

<script>
  import lookups from '@/mixins/lookups';

  import Identifier from '@/views/login/components/Identifier';
  import Otp from '@/views/login/components/Otp';
  import Restart from '@/views/login/components/Restart';

  export default {

    components: { Identifier, Otp, Restart },

    data: () => ({
      problem: false,
      identifier: undefined,
      key: undefined,
      otp: undefined,
    }),

    computed: {
      domain() { return window.location.hostname.split('.')[0]; },
    },

    methods: {
      restart() {
        this.problem = false;
        this.identifier = undefined;
        this.key = undefined;
        this.otp = undefined;
      },
      login(payload) {
        this.$store.commit('setAuthKey', this.key );
        this.$store.commit('setUser', payload);
        if ((payload['customer_uuids'] || []).length==0) {
          this.$store.commit('customersHaveBeenInitialised');
        } else {
          this.$store.dispatch('addCustomersToCache', payload['customer_uuids']);
        }
      },
    },

    watch: {
      identifier(to) {
        if (to) {
          this.problem = false;
          this.remoteLookup('sendOtp', { identifier: this.identifier, domain: this.domain }).then(e => this.key = e.key).catch(e => { this.problem = true; this.identifier = undefined; });
        }
      },
      otp(to) {
        if (to) {
          this.remoteLookup('validateOtp', { key: this.key, otp: to }).then(e => this.login(e)).catch(e => { this.problem = true; this.identifier = undefined; });
        }
      }
    },

    mixins: [lookups],

  };
</script>


<i18n>
{
  "en": {
    "message": "Oops. Something went wrong. Please restart."
  },
  "es": {
    "message": "Dios mío, algo salió mal. Por favor reinicie."
  },
  "sw": {
    "message": "Ah mpenzi, kuna kitu kilienda vibaya. Tafadhali anzisha upya."
  }
}
</i18n>
