<template>
  <PageHeader :title="field.name || 'New Field'" :subtitle="customer.name" icon="mdi-focus-field" @subtitle="toField" @back="toField" @save="submit" back save :valid='formValid' >

    <v-form>

      <FormContainer color="blue lighten-5">

        <v-container fluid class="py-0 px-2">
          <v-row>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['FieldLocation']" title="Field Location" description="Is the field susceptible to flooding or water damming?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['Altitude']" title="Altitude" description="Is the altitude above sea level ideal for the recommended variety of chili to be grown?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['DryZone']" title="Dry Zone" description="Is there low level of rain water precipitation?"  /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['WaterAvailability']" title="Water Availability" description="The farm has good water availability (rivers, streams, reservoirs)?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['SoilAnalysis']" title="Soil Analysis" description="The soil has - 40% of clay or good drainage capacity?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['Owner']" title="Owner" description="The farmer is owner of the land or has a regular rent contract?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['DripIrrigation']" title="Drip Irrigation" description="The field/farm already has a drip irrigation system installed?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['LabourAvailable']" title="Hand Labour" description="Is there enough hand labor near or at the farm for sowing and harvest periods?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['Experience']" title="Agricultural Experience" description="The farmer has good agricultural experience?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['FinancialResources']" title="Financial Resources" description="The farmer has good financial resources?" /></v-col>

            <v-col cols="12">
              <v-textarea label="Observations" v-model="assessment.Observations" auto-grow ></v-textarea>
            </v-col>
            
          </v-row>
        </v-container>

      </FormContainer>
    </v-form>

  </PageHeader>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import StarRatingCard from '@/components/StarRatingCard';

  export default {
    components: { PageHeader, FormContainer, StarRatingCard },
    data: () => ({
      assessment: {
        uuid: uuidv4(),
        Observations: ''
      },
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      field() { return this.customer.fields.filter( f => f.uuid==this.fieldUuid )[0]; },
      formValid() { return Object.keys(this.assessment).length==12 }, // we already have two elements in the set: Observations and uuid
    },

    methods: {

      submit() {

        let score = 0;
        const results = this.assessment;

        score = score + results['FieldLocation'] * 0.13;
        score = score + results['Altitude'] * 0.08;
        score = score + results['DryZone'] * 0.1;
        score = score + results['WaterAvailability'] * 0.12;
        score = score + results['SoilAnalysis'] * 0.12;
        score = score + results['Owner'] * 0.05;
        score = score + results['DripIrrigation'] * 0.13;
        score = score + results['LabourAvailable'] * 0.09;
        score = score + results['Experience'] * 0.09;
        score = score + results['FinancialResources'] * 0.09;

        score = Math.round(score);
        this.field.score = score;
        results['_score'] = score;

        this.field.assessmentScores = this.field.assessmentScores || [];
        this.field.assessmentScores.push(results);

        if (this.field.status==='new') { this.field.status = 'assessed'; }
        this.field.scoredAt = new Date().toISOString();

        this.$store.dispatch('upsertCustomer', this.customer);
        
        this.toField();

      },

      toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },
      
    }
  }
  
</script>