<template>
  <div class="d-inline-flex mx-0 px-0">

<!-- 
      <HeaderBarIcon icon="mdi-google-maps" tooltip="GPS Details" to="LocationDetail" />
      <HeaderBarIcon icon="mdi-account-plus-outline" tooltip="Capture Farmer" to="CreateFarmer" />
      <HeaderBarIcon icon="mdi-animation-outline" tooltip="Photos" to="Photos" />
 -->

      <HeaderBarIcon icon="mdi-account-search" :tooltip="$t('Online search')" to="Search" />
      <HeaderBarIcon icon="mdi-account-multiple" :tooltip="$t('Farmers and Contacts')" to="Customers" />
      <HeaderBarIcon icon="mdi-teach" :tooltip="$t('Teach')" to="PptlTzTeach" />
      <HeaderBarIcon icon="mdi-account-tie-outline" :tooltip="$t('Add Vendor')" to="PptlTzVendorAdd" />
      <HeaderBarIcon icon="mdi-account-plus" :tooltip="$t('Create New Contact')" to="CreateContact" />

      <AppMenu />

  </div>

</template>

<script>
import AppMenu from '@/components/AppMenu';
import HeaderBarIcon from '@/components/HeaderBarIcon';
export default {
  components: {
    HeaderBarIcon,
    AppMenu,
  },
  data() {
    return { }
  },
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode },
  },
  watch: {
    
  },
};
</script>


<i18n>
{
  "en": {
    "Online search": "Online search",
    "Farmers and Contacts": "Farmers and Contacts",
    "Vouchers": "Vouchers",
    "Create New Contact": "Create New Contact"
  },
  "es": {
    "Online search": "Búsqueda en línea",
    "Farmers and Contacts": "Agricultores y Contactos",
    "Vouchers": "Vales",
    "Create New Contact": "Crear nuevo Contacto"
  },
  "sw": {
    "Online search": "Utafutaji wa mkondoni",
    "Farmers and Contacts": "Wakulima na Mawasiliano",
    "Vouchers": "Vocha",
    "Create New Contact": "Unda Mawasiliano Mpya"
  }
}
</i18n>
