var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"w-100",attrs:{"dense":"","headers":_vm.headers,"items":_vm.customers,"item-key":"item.uuid","search":_vm.search},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.getType(item))+" ")])]}},{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.isAFarmer",fn:function(ref){
var item = ref.item;
return [_c('IdentityNumber',{attrs:{"demographic":item.demographic}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'ShowCustomer', query: { uuid: item.uuid } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-account")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }