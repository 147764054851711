<template>
  <span>

    <v-icon small v-if="gender=='female'">mdi-gender-female</v-icon>
    <span v-if="gender=='female'">Female</span>

    <v-icon small v-if="gender=='male'">mdi-gender-male</v-icon>
    <span v-if="gender=='male'">Male</span>

  </span>
</template>

<script>

export default {

  props: ['gender'],

};
</script>
