<template>
  <span @click="updateLocation">
    <v-icon :title="title" color="primary" :disabled="!location" dense >mdi-crosshairs-gps</v-icon>
  </span>
</template>

<script>
export default {
  computed: {
    location() { return this.$store.getters.geolocation; },
    accuracy() {
      let a = null;
      if ((location) && (this.location)) {
        let coords = this.location.coords;
        if (coords) { a = coords.accuracy; }
      }
      return a;
    },
    title() {
      let t = 'Location unavailable';
      if (this.accuracy) { t = `${this.accuracy} meters`; }
      return t;
    }
  },
  methods: {
    updateLocation() {
      this.$store.dispatch('updateGeolocation');
    }
  }
};
</script>
