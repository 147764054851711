<template>
  <v-card>
    <v-card-title>
      {{$t('fields')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="toNewField">{{$t('new')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="field in fields" :key="field.id" :title="field.name" >
              <td class="text-left">{{ crops[field.cropId] }}</td>
              <td class="text-left hidden-xs-only">{{ fieldLandSize(field) }} <small>{{ fieldLandSizeUnit(field) }}</small></td>
              <td class="text-right">
                <v-btn v-if="hrContract(field)" color="primary" text :to="{name: 'HRContractDetail', query: { uuid: customer.uuid, fieldUuid: field.uuid } }" class="mr-1">
                  <v-icon dense>mdi-format-line-style</v-icon>
                </v-btn>                
                <AssessButton v-if="showScoreLink(field)" :customer="customer" :field="field" icon text />
                <v-btn color="primary" text :to="{name: 'Field', query: { uuid: customer.uuid, fieldUuid: field.uuid } }" class="ml-1">
                  <v-icon dense>mdi-focus-field</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>


<script>
  import fields from '@/mixins/fields';

  import Row from '@/components/Row';
  import Time from '@/components/Time';
  import AssessButton from '@/views/fields/components/AssessButton';

  export default {

    components: { Row, Time, AssessButton },

    props: ['customer'],

    computed: {
      fields() { return (this.customer.fields || []); },
      crops() { return this.$store.getters.cropLookups; },
      partnerCode() { return this.$store.getters.partnerCode; },
      hr() { return ['hr_co', 'winwin_zw'].includes(this.partnerCode); },
    },

    methods: {
      toNewField() { this.$router.push({name: 'FieldEdit', query: { uuid: this.$route.query.uuid, fieldUuid: 'new' }}); },
      hrContract(field) { return (this.hr && !(field.payload || {})['hrContractDetailAt'] ); },
      showScoreLink(field) { return (this.hr && !field.scoredAt ); },
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "fields": "Fields"
  },
  "es": {
    "fields": "Cultivos"
  },
  "sw": {
    "fields": "Mashamba"
  }
}
</i18n>
