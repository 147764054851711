<template>

  <v-form v-model="validForm">
    <div class="mb-4 font-weight-light">{{$t('message')}}</div>
    <div>
      <v-text-field placeholder="123456" v-model="otp" :rules="otpRules" ></v-text-field>
    </div>
    <div align="right">
      <v-btn type="submit" @click="$emit('input', otp);" :disabled="!validForm">
        {{$t('go')}}
      </v-btn>
    </div>
    <div class="mt-3" align="center" v-if="showHelp">
      {{$t('noPin')}} {{$t('sentTo')}} {{identifier}}. {{$t('help')}}
    </div>
  </v-form>

</template>

<script>

export default {
  props: ['identifier'],
  data: () => ({
    validForm: false,
    otp: null,
    showHelp: false,
    otpRules: [
      v => /^\d{6}$/.test(v)
    ],
  }),
  mounted: function () {
    window.setInterval(() => { this.showHelp = true; }, 15000)
  },
};
</script>


<i18n>
{
  "en": {
    "message": "We have sent you a PIN code. Please enter this below.",
    "go": "Go",
    "noPin": "No PIN received?",
    "sentTo": "We sent the PIN to",
    "help": "Is this the right phone number or email address? If it is right, maybe wait a little longer. Otherwise, restart using the link below."
  },
  "es": {
    "message": "Le hemos enviado un código PIN. Por favor ingrese esto a continuación.",
    "go": "Ir",
    "noPin": "¿No recibió PIN?",
    "sentTo": "Enviamos el PIN al",
    "help": "¿Es este el número de teléfono o la dirección de correo electrónico correctos? Si es correcto, quizás espere un poco más. De lo contrario, comience de nuevo utilizando el enlace a continuación."
  },
  "sw": {
    "message": "Tumekutumia nambari ya siri. Tafadhali ingiza hii hapa chini.",
    "go": "Nenda",
    "noPin": "Hakuna PIN iliyopokelewa?",
    "sentTo": "Tulituma PIN hiyo kwa",
    "help": "Hii ni nambari sahihi ya simu au anwani ya barua pepe? Ikiwa ni sawa, labda subiri kidogo. Vinginevyo, anza kutumia kiunga hapa chini."
  }
}
</i18n>
