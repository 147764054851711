<template>
  <v-select :items="languages" :label="$t('Primary Language')" v-model="language" />
</template>


<script>
  export default {

    props: ['value'],

    data: () => ({
      language: undefined,
      perCountry: {
        'CO': ['Spanish'],
        'TZ': ['Swahili', 'English'],
        'ZA': ['Pedi', 'Sotho', 'Tswana', 'Swati', 'Venḓa', 'Tsonga', 'Afrikaans', 'English', 'Ndebele', 'Xhosa', 'Zulu'],
        'ZM': ["Bemba", "Nyanja", "Lozi", "Tonga", "Luvale", "Lunda", "Kaonde"],
        'UG': ['Swahili', 'English'],
      },
      defaultLanguages: ['English', 'Swahili', 'French', 'Arabic', 'Spanish'],
    }),

    computed: {
      countryCode() { return this.$store.getters.settings.countryCode; },
      languages() { return this.perCountry[this.countryCode] || this.defaultLanguages; },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.language = to; }
      },
      language(to) { this.$emit('input', to); },
    },


  }
</script>


<i18n>
{
  "en": {
    "Primary Language": "Primary Language"
  },
  "es": {
    "Primary Language": "Lenguaje Primario"
  },
  "sw": {
    "Primary Language": "Lugha Msingi"
  }
}
</i18n>
