<template>
  <PageHeader :title="field.name || 'New Field'" :subtitle="customer.name" icon="mdi-focus-field" @subtitle="toField" @back="toField" @save="submit" back save :valid='formValid' >

    <v-form>


      <FormContainer color="blue lighten-5">

        <v-container fluid class="py-0 px-2">
          <v-row>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['FieldLocation']" :title="$t('Field Location')" description="Is the field well located? Is it susceptible to flooding or water damming?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['WaterAvailability']" :title="$t('Water Availability')" description="The farm has good water availability (rivers, streams, reservoirs)?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['SoilAnalysis']" :title="$t('Soil Analysis')" description="Is the soil quality good?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['Owner']" :title="$t('Owner')" description="The farmer is owner of the land or has a regular rent contract?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['DripIrrigation']" :title="$t('Irrigation')" description="Does the field have an irrigation system?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['Experience']" :title="$t('Agricultural Experience')" description="Is the farmer experience?" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['FinancialResources']" :title="$t('Financial Resources')" description="Does the farmer have adequate financial resources to afford seed, fertiliser etc?" /></v-col>

            <v-col cols="12">
              <v-textarea label="Observations" v-model="assessment.Observations" auto-grow ></v-textarea>
            </v-col>
            
          </v-row>
        </v-container>

      </FormContainer>
    </v-form>

  </PageHeader>

</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import StarRatingCard from '@/components/StarRatingCard';

  export default {
    components: { PageHeader, FormContainer, StarRatingCard },
    data: () => ({
      assessment: {
        uuid: uuidv4(),
        Observations: ''
      },
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      field() { return this.customer.fields.filter( f => f.uuid==this.fieldUuid )[0]; },
      formValid() { return Object.keys(this.assessment).length==9 }, // we already have two elements in the set: Observations and uuid
    },

    methods: {

      submit() {

        let score = 0;
        const results = this.assessment;

        score = score + results['FieldLocation'] * (1/7);
        score = score + results['WaterAvailability'] * (1/7);
        score = score + results['SoilAnalysis'] * (1/7);
        score = score + results['Owner'] * (1/7);
        score = score + results['DripIrrigation'] * (1/7);
        score = score + results['Experience'] * (1/7);
        score = score + results['FinancialResources'] * (1/7);


        score = Math.round(score);
        this.field.score = score;
        results['_score'] = score;

        this.field.assessmentScores = this.field.assessmentScores || [];
        this.field.assessmentScores.push(results);

        if (this.field.status==='new') { this.field.status = 'assessed'; }
        this.field.scoredAt = new Date().toISOString();

        this.$store.dispatch('upsertCustomer', this.customer);
        
        this.toField();

      },

      toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },
      
    }
  }
  
</script>


<i18n>
{
  "en": {
    "Field Location": "Field Location",
    "Water Availability": "Water Availability",
    "Soil Analysis": "Soil Analysis",
    "Owner": "Owner",
    "Irrigation": "Irrigation",
    "Agricultural Experience": "Agricultural Experience",
    "Financial Resources": "Financial Resources"
  },
  "es": {
    "Field Location": "Ubicación del campo",
    "Water Availability": "Disponibilidad de agua",
    "Soil Analysis": "Análisis de suelo",
    "Owner": "Dueño",
    "Irrigation": "Irrigación",
    "Agricultural Experience": "Experiencia agrícola",
    "Financial Resources": "Recursos financieros"
  },
  "sw": {
    "Field Location": "Mahali pa Shamba",
    "Water Availability": "Upatikanaji wa Maji",
    "Soil Analysis": "Uchambuzi wa Udongo",
    "Owner": "Mmiliki",
    "Irrigation": "Umwagiliaji",
    "Agricultural Experience": "Uzoefu wa Kilimo",
    "Financial Resources": "Rasilimali Fedha"
  }
}
</i18n>
