var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.customers,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('show', item)}}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.identity_number",fn:function(ref){
var item = ref.item;
return [_c('IdentityNumber',{attrs:{"demographic":item.demographic}})]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('LocationLink',{attrs:{"location":item.location}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }