<template>
  <PageHeader :title="customer.name"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

    <FormContainer>

      <v-row>
        <v-col cols="12" md="6" v-if="fieldAgents && fieldAgents.length>0">
          <FieldAgentInput v-model="customer.fieldAgent" />
        </v-col>
        <v-col cols="12" md="6" v-if="depots && depots.length>0">
          <DepotInput v-model="customer.depot" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-select :items="crops" label="Primary Crop" class="text-left" v-model="customer.primaryCrop"></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <LandSizeInput label="Land size" v-model="customer.landSize" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <FarmingActivities title="Livestock" icon="mdi-cow" type="livestock" :activities="livestock" v-model="stock" />
        </v-col>
        <v-col cols="12" lg="6">
          <FarmingActivities title="Agriculture" icon="mdi-spa-outline" type="crop" :activities="crops" v-model="farmCrops" />
        </v-col>
      </v-row>

    </FormContainer>

  </PageHeader>
</template>


<script>

  import PageHeader from '@/components/PageHeader';
  import Row from '@/components/Row';
  import FormContainer from '@/components/FormContainer';
  import FieldAgentInput from '@/components/FieldAgentInput';
  import DepotInput from '@/components/DepotInput';
  import LandSizeInput from '@/components/LandSizeInput';
  import FarmingActivities from '@/views/farmers/components/FarmingActivities';

  export default {

    components: { PageHeader, Row, FormContainer, FieldAgentInput, DepotInput, LandSizeInput, FarmingActivities },

    data: () => ({
      formValid: true,
      farmCrops: {},
      stock: {},
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },
      depotName() { return (this.customer.depot ? this.customer.depot.name : null) },
      anyFields() { return (this.fieldAgent || this.depotName); },
      crops() { return this.$store.getters.cropNames; },
      fieldAgents() { return this.$store.getters.settings.fieldAgents; },
      depots() { return this.$store.getters.settings.depots; },
      livestock() { return ['poultry', 'pigs', 'dairy', 'beef', 'small stock', 'other_livestock']; },
      crops() { return ['maize', 'wheat', 'beans', 'sunflowers'] },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            this.farmCrops = (to.farm || {}).crops || {};
            this.stock = (to.farm || {}).livestock || {};
          } else {
            this.crops = {};
            this.stock = {};
          }
        }
      },
      farmCrops(to) {
        this.customer.farm = this.customer.farm || {};
        this.customer.farm.crops = to;
      },
      stock(to) {
        this.customer.farm = this.customer.farm || {};
        this.customer.farm.livestock = to;
      },
    },

    methods: {
      save() {
        this.$store.dispatch('upsertCustomer', this.customer);
        this.toCustomer();
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
    },

  }
</script>
