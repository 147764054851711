<template>
  <span>
    <v-img max-height="40" max-width="40" contain v-if="logo==='sasa'"      src="@/assets/logo.png" />
    <v-img max-height="40" max-width="40" contain v-if="logo==='hr_co'"     src="@/assets/headerBarIcons/hr_co.png" />
    <v-img max-height="40" max-width="40" contain v-if="logo==='winwin_zw'" src="@/assets/winwin_zw.jpg" />
    <span class="white--text text-h5" v-if="logo==='apex_tz'">eMazao</span>
  </span>
</template>

<script>
  export default {

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      logo() {
        let l = 'sasa';
        if (['apex_tz', 'hr_co', 'winwin_zw'].includes(this.partnerCode)) {
          l = this.partnerCode;
        }
        return l;
      }
    },
  };
</script>
