<template>

  <v-container fluid >
    <v-row no-gutters>

      <v-col cols="12" sm="4">
        <v-combobox
          @change="changed0"
          v-model="geographic0"
          :items="geographicOptions0"
          :label="$t(geographicHeirarchy[0])"
          :hint="$t(geographicHeirarchy[0])"
          single-line
        ></v-combobox >
      </v-col>
    
      <v-col cols="12" sm="4">
        <v-combobox
          @change="changed1"
          v-model="geographic1"
          :items="geographicOptions1"
          :label="$t(geographicHeirarchy[1])"
          :hint="$t(geographicHeirarchy[1])"
          single-line
        ></v-combobox >
      </v-col>
    
      <v-col cols="12" sm="4">
        <v-combobox
          v-model="geographic2"
          :items="geographicOptions2"
          :label="$t(geographicHeirarchy[2])"
          :hint="$t(geographicHeirarchy[2])"
          single-line
        ></v-combobox >
      </v-col>

    </v-row>
  </v-container>

</template>

<script>

  export default {

    props: ['value'],

    data: () => ({
      geographic0: '',
      geographic1: '',
      geographic2: '',
      geographicOptions0: [],
      geographicOptions1: [],
      geographicOptions2: [],
    }),

    computed: {
      result() { return [this.geographic0, this.geographic1, this.geographic2]; },
      geographicHeirarchy() { return this.$store.getters.settings.geographicHeirarchy; },
      geographicStructure() { return this.$store.getters.settings.geographicStructure; },
    },

    mounted() {

    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.geographic0 = to[0] || '';
            this.geographic1 = to[1] || '';
            this.geographic2 = to[2] || '';
          } else {
            this.geographic0 = '';
            this.geographic1 = '';
            this.geographic2 = '';
          }
          this.setGeographicOptions();
        }
      },
      result(to) { this.$emit('input', to); },
    },

    methods: {

      setGeographicOptions() {
        this.geographicOptions0 = this.getGeographicOptions0();
        this.geographicOptions1 = this.getGeographicOptions1();
        this.geographicOptions2 = this.getGeographicOptions2();
      },

      getGeographicOptions0() { return Object.keys(this.geographicStructure).sort(); },
      getGeographicOptions1() {
        let places = {};
        if (this.geographic0) { places = this.geographicStructure[this.geographic0]; }
        places = places || {};
        return Object.keys(places).sort();
      },
      getGeographicOptions2() {
        let places = [];
        if (this.geographic0 && this.geographic1) { places = (this.geographicStructure[this.geographic0] || {})[this.geographic1]; }
        places = places || [];
        return places.sort();
      },


      changed0() {
        this.geographic1 = '';
        this.geographic2 = '';
        this.geographicOptions1 = this.getGeographicOptions1();
        this.geographicOptions2 = this.getGeographicOptions2();
      },
      changed1() {
        this.geographic2 = '';
        this.geographicOptions2 = this.getGeographicOptions2();
      },
    },

  }
</script>


<i18n>
{
  "en": {
    "Area": "Area",
    "District": "District",
    "Province": "Province",
    "Region": "Region",
    "Town": "Town",
    "Village": "Village",
    "Ward": "Ward"
  },
  "es": {
    "Area": "Municipio",
    "District": "District",
    "Province": "Province",
    "Region": "Departamento",
    "Town": "Ciudad / Pueblo",
    "Village": "Village",
    "Ward": "Ward"
  },
  "sw": {
    "Area": "Eneo",
    "District": "Wilaya",
    "Province": "Mkoa",
    "Region": "Mkoa",
    "Town": "Mji",
    "Village": "Kijiji",
    "Ward": "Kata"
  }
}
</i18n>
