<template>
  <v-card>
    <v-carousel v-model="model">

      <v-carousel-item v-for="image in images" light>
        <v-sheet height="100%" tile >
          <v-row class="fill-height" align="center" justify="center">
            <SingleImage :image="image" />
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
  import SingleImage from '@/components/SingleImage';

  export default {
    components: { SingleImage },

    props: ['images'],

    data: () => ({
      model: 0,
    }),

    // watch: {
    //   images: {
    //     immediate: true,
    //     handler(to) {
    //       this.srcs = [];
    //       this.images.forEach(image => {
    //       if (typeof(image)==='string') {
    //         this.srcs.push(image.url);
    //       } else if (image.uuid) {
    //         ImageDb.url(image.uuid).then( e => this.srcs.push(e) );
    //       }
    //     })},
    //   }
    // }
  }
</script>